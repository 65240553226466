import classnames from 'classnames';
import React from 'react';
import { CircleInfoIcon, Icon, Typography, FontWeight } from 'src';
import { BorderRadius, FontFamily, Size } from 'src/utils/constants';
import { IconTooltip } from '..';
import { InputSize, InputTheme, TextFieldVariant } from './theme';

type CommonProps = {
  iconLeft?: string | React.FC<React.SVGProps<SVGElement>>;
  iconRight?: string | React.FC<React.SVGProps<SVGElement>>;
  iconRightWidth?: string;
  iconRightHeight?: string;
  iconRightTopPadding?: string;
  onRightIconClick?: () => void;
  iconLeftColor?: string;
  iconRightColor?: string;
  variant?: TextFieldVariant;
  size?: Size;
  borderRadius?: BorderRadius;
  label?: string;
  fontFamily?: FontFamily;
  fontWeight?: FontWeight;
  multiline?: boolean;
  errorText?: string;
  successMode?: boolean;
  border?: boolean;
  prefixText?: string;
  disabled?: boolean;
  labelTooltip?: React.ReactNode;
  showDropShadow?: boolean;
  fontSize?: string;
};

export type InputProps =
  | {
      multiline?: false;
      inputProps: Omit<React.HTMLProps<HTMLInputElement>, 'className' | 'size'>;
    }
  | {
      multiline: true;
      inputProps: Omit<
        React.HTMLProps<HTMLTextAreaElement>,
        'className' | 'size'
      >;
    };

export type TextFieldProps = CommonProps & InputProps;

const TextField = ({
  iconLeft,
  iconRight,
  iconRightHeight = '4',
  iconRightWidth = '4',
  iconRightTopPadding = '3',
  iconLeftColor = 'var(--color-primaryBlack-400)',
  iconRightColor = 'var(--color-primaryBlack-400)',
  onRightIconClick,
  fontFamily = 'montserrat',
  borderRadius = 'default',
  variant = 'primary',
  fontWeight = 'normal',
  size = 'md',
  label,
  errorText,
  multiline = false,
  successMode = false,
  inputProps,
  prefixText,
  disabled = false,
  labelTooltip,
  showDropShadow = false,
  fontSize = 'sm',
}: TextFieldProps) => {
  const Component = multiline ? 'textarea' : 'input';

  const minRows = inputProps?.rows || 2;
  const [textareaRows, setTextareaRows] = React.useState(minRows);

  // Autoexpand or shrink rows for textarea
  React.useEffect(() => {
    if (!multiline) return;
    const rowlen = (inputProps?.value as string)?.split('\n') || 0;
    if (rowlen.length > minRows) {
      setTextareaRows(rowlen.length);
    } else {
      setTextareaRows(minRows);
    }
  }, [inputProps.value]);

  const overrideProps: Record<string, unknown> = {};
  if (multiline) overrideProps.rows = textareaRows;

  return (
    <div className="flex-1">
      <div className="relative">
        {label && (
          <div className="absolute">
            <div className="flex items-end">
              <p className="text-xs text-primaryBlack-200 pt-3 pl-4 pr-1.5">
                {label}
              </p>
              {labelTooltip && (
                <div className="flex mb-0.5">
                  <IconTooltip
                    iconProps={{
                      src: CircleInfoIcon,
                      fill: 'gray',
                      width: 3.5,
                      height: 3.5,
                    }}
                  >
                    {labelTooltip}
                  </IconTooltip>
                </div>
              )}
            </div>
          </div>
        )}
        {iconLeft && (
          <div
            className="icon-left absolute inset-y-0 left-0 pl-3 pt-3 flex pointer-events-none"
            aria-hidden="true"
            data-testid="icon-left"
          >
            <Icon fill={iconLeftColor} src={iconLeft} width="5" height="5" />
          </div>
        )}
        {prefixText && (
          <div
            className={classnames(
              'absolute inset-y-0 pl-3 pt-3 flex pointer-events-none text-sm text-gray-600',
              {
                'left-6': iconLeft,
                'left-0': !iconLeft,
              },
            )}
          >
            {prefixText}
          </div>
        )}
        {iconRight && (
          <div
            className={classnames(
              'icon-right absolute z-10 cursor-pointer inset-y-0 right-0 pr-3 flex',
              {
                [`pt-${iconRightTopPadding}`]: !label,
                'pt-5': label,
              },
            )}
            aria-hidden="true"
            onClick={onRightIconClick}
            data-testid="icon-right"
          >
            <Icon
              fill={iconRightColor}
              src={iconRight}
              width={iconRightWidth}
              height={iconRightHeight}
            />
          </div>
        )}
        {/*@ts-ignore*/}
        <Component
          className={classnames(
            'resize-none block w-full rounded-md focus:outline-none px-4',
            `font-${fontFamily}`,
            borderRadius === 'default' ? 'rounded' : `rounded-${borderRadius}`,
            `text-base md:text-${fontSize}`,
            `font-${fontWeight}`,
            InputTheme[variant].textColor,
            InputTheme[variant].placeholderColor,
            InputTheme[variant].backgroundColor,
            InputTheme[variant].activeClass && InputTheme[variant].activeClass,
            {
              'border border-solid': true,
              'pt-6.5 pb-2': label,
              [InputSize[size]]: !label,
              'pl-7': prefixText,
              'pl-10': iconLeft,
              'pl-15': iconLeft && prefixText,
              'text-grayCream-500': disabled,
              [InputTheme[variant].borderColor]: !errorText && !successMode,
              'border-red-400': errorText,
              'border-emerald-400': successMode,
              'shadow-md': showDropShadow,
            },
          )}
          disabled={disabled}
          {...inputProps}
          {...overrideProps}
          data-testid={inputProps?.id}
        />
      </div>
      {errorText && (
        <div className="mt-1.5" data-testid="textfield-error">
          <Typography variant="caption" color="red-400">
            {errorText}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default TextField;
