export type TextFieldVariant =
  | 'primary'
  | 'light-gray'
  | 'new-light-gray'
  | 'transparent-no-border';

export type InputThemeType = {
  [key in TextFieldVariant]: {
    backgroundColor: string;
    borderColor: string;
    textColor: string;
    placeholderColor: string;
    activeClass?: string;
  };
};
export const InputTheme: InputThemeType = {
  primary: {
    backgroundColor: 'bg-white',
    borderColor: 'border-primaryGray-400',
    textColor: 'text-primaryBlack-400',
    placeholderColor: 'placeholder-primaryBlack-200',
  },
  'light-gray': {
    backgroundColor: 'bg-lightGray-400',
    borderColor: 'border-transparent',
    textColor: 'text-primaryBlack-400',
    placeholderColor: 'placeholder-lightGray-50',
  },
  'transparent-no-border': {
    backgroundColor: 'bg-transparent',
    borderColor: 'border-transparent',
    textColor: 'text-primaryBlack-400',
    placeholderColor: 'placeholder-lightGray-50',
  },
  'new-light-gray': {
    backgroundColor: 'bg-newGray-100',
    borderColor: 'border-transparent',
    textColor: 'text-primaryBlack-400',
    placeholderColor: 'placeholder-lightGray-50',
    activeClass: 'active:border-newGray-900 focus:border-newGray-900',
  },
};

export const InputSize = {
  xs: 'py-1.5',
  sm: 'py-2',
  md: 'py-2.5',
  lg: 'py-4',
};
