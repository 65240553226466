import React from 'react';
import { Icon, IconProps } from '..';
import classnames from 'classnames';
import Tippy from '@tippyjs/react/headless';

interface IconTooltipProps {
  iconProps: IconProps;
  children: React.ReactNode;
  width?: string;
}

const IconTooltip = ({
  iconProps,
  children,
  width = '60',
}: IconTooltipProps) => {
  return (
    <Tippy
      placement="bottom"
      interactive={true}
      popperOptions={{
        strategy: 'fixed',
      }}
      render={(attrs) => (
        <div
          data-testid="tooltip-content"
          className={classnames(`w-${width}`)}
          {...attrs}
        >
          <p
            className={classnames(
              'p-2 mt-1 text-sm rounded-xl bg-secondaryGray-500 text-primaryBlack-400 text-center',
              'shadow-lg',
            )}
          >
            {children}
          </p>
        </div>
      )}
    >
      <button
        onClick={(e) => e.stopPropagation()}
        data-testid="tooltip-wrapper"
      >
        <Icon {...iconProps} />
      </button>
    </Tippy>
  );
};

export default IconTooltip;
