import { EyeIcon } from '@heroicons/react/solid';
import React from 'react';
import {
  TextField,
  TextFieldProps,
  ErrorIcon,
  GreenCircleCheckIcon,
} from 'src';

type CommonProps = {
  label?: string;
  defaultIcon?: string | React.FC<React.SVGProps<SVGElement>>;
  successIcon?: string | React.FC<React.SVGProps<SVGElement>>;
  errorIcon?: string | React.FC<React.SVGProps<SVGElement>>;
  showPasswordToggle?: boolean;
};

export type FormInputProps = TextFieldProps & CommonProps;

type TextFieldInputProps = Omit<
  React.HTMLProps<HTMLInputElement | HTMLTextAreaElement>,
  'className' | 'size' | 'ref'
>;

const FormInput = (props: FormInputProps) => {
  // text in password field can be shown or hidden.
  const [passwordFieldType, setPasswordFieldType] = React.useState('password');

  const {
    showPasswordToggle,
    defaultIcon = '',
    successIcon = GreenCircleCheckIcon,
    errorIcon = ErrorIcon,
    errorText,
    successMode,
    inputProps,
    onRightIconClick,
  } = props;
  const isSuccessState = successMode;
  const isErrorState = errorText && !successMode;

  const getIcon = () => {
    if (isErrorState) {
      return errorIcon;
    }
    if (isSuccessState) {
      return successIcon;
    }
    if (showPasswordToggle) {
      return EyeIcon;
    }
    return defaultIcon;
  };

  const onPasswordToggleButton = () => {
    if (showPasswordToggle && !isSuccessState && !isErrorState) {
      setPasswordFieldType(
        passwordFieldType === 'password' ? 'text' : 'password',
      );
    }
  };

  return (
    <TextField
      iconRight={getIcon()}
      {...props}
      inputProps={{
        ...(inputProps as TextFieldInputProps),
        type: showPasswordToggle ? passwordFieldType : inputProps.type,
      }}
      onRightIconClick={
        showPasswordToggle ? onPasswordToggleButton : onRightIconClick
      }
    ></TextField>
  );
};

export default FormInput;
